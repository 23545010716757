import React from 'react';
import { FormattedMessage } from 'react-intl';

import ImageBlock from 'blocks/ImageBlock';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import NextButton from 'screens/funnels/NextButton';
import { FormType } from 'utils/hooks/useForm';
import { sanitizeNumberInput } from 'utils/sanitize_number_input';

const ClassSizeScreen = ({
  form,
  onSubmit,
}: {
  form: FormType;
  onSubmit: () => void;
}) => (
  <PageStackBlock>
    <PageHeader headline={<FormattedMessage id="v2 funnel class size headline" />} text={<FormattedMessage id="v2 funnel class size info" />} />

    <TextInputComponent
      name="class_size"
      {...form.bindInput('class_size')}
      autoFocus={true}
      type="number"
      step="1"
      onKeyDown={(event: { key: string; preventDefault: () => any }) =>
        sanitizeNumberInput(event)
      }
    />

    <NextButton
      condition={!form.loading}
      onClick={onSubmit}
    >
      <FormattedMessage id="Next" />
    </NextButton>

    <ImageBlock fullWidth={true}>
      <img src="/images/funnels/funnel_org.png" width="100%" alt="" />
    </ImageBlock>
  </PageStackBlock>
);

export default ClassSizeScreen;
