import React from 'react';
import { FormattedMessage } from 'react-intl';

import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import InputMessage from 'elements/InputMessage';
import Checkboxes from 'screens/funnels/Checkboxes';
import NextButton from 'screens/funnels/NextButton';
import COLORS from 'utils/constants/colors';
import { FormType } from 'utils/hooks/useForm';

const ROLES = [
  'Planung',
  'Design',
  'Finanzen',
  'Elternteil',
  'Lehrer',
  'Andere'
];

const RoleScreen = ({
  form,
  onSubmit
}: {
  form: FormType;
  onSubmit: () => void;
}) => (
  <PageStackBlock>
    <PageHeader
      headline={<FormattedMessage id="v2 funnel role headline" />}
      text={<FormattedMessage id="v2 funnel role info" />}
    />

    <Checkboxes form={form} options={ROLES} name="roles" translate={false} />

    {form.errors.roles && (
      <InputMessage
        error={true}
        style={{
          color: `${COLORS.BLACK}`,
          backgroundColor: `${COLORS.RED_LIGHT}`
        }}
      >
        {form.errors.roles}
      </InputMessage>
    )}

    <NextButton onClick={onSubmit} condition={!form.loading}>
      <FormattedMessage id="Next" />
    </NextButton>
  </PageStackBlock>
);

export default RoleScreen;
