import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { FormType } from 'utils/hooks/useForm';
import { validYears } from 'utils/validYears';

const YearScreen = ({
  form,
  nextScreen
}: {
  form: FormType;
  nextScreen: string;
}) => (
  <PageStackBlock>

    <PageHeader
      headline={<FormattedMessage id="v2 funnel year headline" />}
      text={<FormattedMessage id="v2 funnel year info" />}
    />

    <ListStackBlock>
      {validYears().map((year) => (
        <Link
          key={year}
          to={`?screen=${nextScreen}`}
          onClick={() => form.setField('year', year)}
        >
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>{year}</EveryCardHeadline>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </Link>
      ))}
    </ListStackBlock>
  </PageStackBlock>
);

export default YearScreen;
