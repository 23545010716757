import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import WideBlock from 'blocks/WideBlock';
import ProgressbarComponent from 'components/ProgressbarComponent';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';

import ProgressBoxItem from './ProgressBoxItem';

interface ProgressBoxComponentProps {
  applicationStore?: ApplicationStoreType;
  chaptersStore?: ChaptersStoreType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class ProgressBoxComponent extends React.Component<ProgressBoxComponentProps> {
  private compileItems(): [ReactNode[], number, number] {
    const { applicationStore, chaptersStore } = this.props;
    const { mainDashboard: dashboard } = chaptersStore!;
    const { currentUser, book } = applicationStore!;

    if (!dashboard || !currentUser || !book) {
      return [[], 0, 0];
    }

    let max = 0;
    let done = 0;
    const items: ReactNode[] = [];

    if (applicationStore!.isOrganizer) {
      // deal closed
      max++;
      items.push(
        <ProgressBoxItem key="isClosed" checked={book.is_closed}>
          <FormattedMessage id="next step is closed" />
        </ProgressBoxItem>
      );

      // delivery date
      max++;
      items.push(
        <ProgressBoxItem key="delivery" checked={book.time_plan !== undefined}>
          <FormattedMessage id="next step delivery date" />
        </ProgressBoxItem>
      );

      // price tier
      max++;
      max++;
      items.push(
        <ProgressBoxItem key="priceTier" checked={book.price_tier !== 'default'}>
          <FormattedMessage id="next step price tier" />
        </ProgressBoxItem>
      );

      if (
        book.price_tier === 'gold' ||
        book.price_tier === 'platinum_light' ||
        book.price_tier === 'platinum' ||
        book.price_tier === 'default'
      ) {
        items.push(
          <ProgressBoxItem key="design" checked={book.design_selected}>
            <FormattedMessage id="next step design" />
          </ProgressBoxItem>
        );
      } else if (book.price_tier === 'silver') {
        items.push(
          <ProgressBoxItem key="uploaded" checked={book.custom_pdf_pages_count !== undefined}>
            <FormattedMessage id="next step upload" />
          </ProgressBoxItem>
        );
      }

      // order placed
      max++;
      items.push(
        <ProgressBoxItem key="order" checked={book.orderAtLeastPaymentOpen}>
          <FormattedMessage id="next step order" />
        </ProgressBoxItem>
      );

      // order paid
      max++;
      items.push(
        <ProgressBoxItem key="paid" checked={book.paid}>
          <FormattedMessage id="next step pay" />
        </ProgressBoxItem>
      );

      // @ts-ignore
      done = items.filter((item) => item.props.checked).length;
    }

    return [items, max, done];
  }

  render() {
    const [items, max, done] = this.compileItems();
    if (!items.length) {
      return null;
    }

    return (
      <WideBlock padded={true}>
        <ItemStackBlock gap="S">
          <Headline.Medium>
            <FormattedMessage id="Next steps" />
          </Headline.Medium>

          <ProgressbarComponent
            progressStatus={(done / max) * 100}
            color="GREEN"
            isStatic={true}
          />

          {items}
        </ItemStackBlock>
      </WideBlock>
    );
  }
}

export default ProgressBoxComponent;
