import ItemStackBlock from 'blocks/ItemStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import InputMessage from 'elements/InputMessage';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function SilverBannerComponent() {
  return (
    <InputMessage info={true}>
      <ItemStackBlock gap="XXS">
        <Paragraph weight="BOLD" size="L">
          <FormattedMessage id="silver package banner" />
        </Paragraph>

        <Paragraph>
          <FormattedMessage id="silver package banner info" />
        </Paragraph>
      </ItemStackBlock>
    </InputMessage>
  );
}

export default SilverBannerComponent;
