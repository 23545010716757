import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ImageBlock from 'blocks/ImageBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

const OfferTextilesScreen = ({ onSubmit }: { onSubmit: (value: string) => void }) => (
  <PageStackBlock>
    <PageHeader headline={<FormattedMessage id="v2 funnel offer textiles headline" />} text={<FormattedMessage id="v2 funnel offer textiles info" />} />

    <FormStackBlock>
        <ButtonBlock background="PRIMARY" color="WHITE" onClick={() => onSubmit('yes')}>
          <FormattedMessage id="Yes" />
        </ButtonBlock>
        <ButtonBlock background="SECONDARY_DARK" color="WHITE" onClick={() => onSubmit('no')}>
          <FormattedMessage id="No" />
        </ButtonBlock>
    </FormStackBlock>

    <ImageBlock fullWidth={true}>
      <img src="/images/funnels/funnel_textile.png" width="100%" alt="" />
    </ImageBlock>
  </PageStackBlock>
);

export default OfferTextilesScreen;
