import { useMutation } from "@tanstack/react-query";
import { submitAdditionalFunnel, submitInitialFunnel } from "api/app/fetch_funnels";

export const useSendInitalFunnel = () => {
  return useMutation({
    mutationFn: (data: {[key: string]: string | string[] | number | number[] | boolean;}) =>
      submitInitialFunnel(data),
  });
};

export const useSendAdditionalFunnel = () => {
  return useMutation({
    mutationFn: (data: {[key: string]: string | string[] | number | number[] | boolean;}) =>
      submitAdditionalFunnel(data),
  });
};
