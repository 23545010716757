import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ImageBlock from 'blocks/ImageBlock';
import Divider from 'components/Divider/Divider';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import ContactOnWhatsappButton from 'domain/Buttons/ContactOnWhatsappButton';
import { Link } from 'react-router-dom';
import { ROUTE_ACCOUNT_DASHBOARD, ROUTE_SIGNUP } from 'utils/constants/routes';

const SuccessScreen = ({
  isAuthenticated,
  type,
  classSize,
  usingNuggit
}: {
  isAuthenticated: boolean;
  type: 'bookfunnel' | 'textilefunnel';
  classSize: number;
  usingNuggit: string;
}) => (
  <PageStackBlock>
    <PageHeader
      headline={<FormattedMessage id="v2 funnel success headline" />}
      text={<FormattedMessage id="v2 funnel success info" />}
    />

    {type == 'bookfunnel' ? (
      <>
        {usingNuggit === "yes" ? (
          <ContactOnWhatsappButton
            messageId="v2 funnel continue whatsapp"
            text={`%0AHallo%20liebes%20nuggit-Team%20%F0%9F%91%8B%2C%0A%0Aich%20hoffe%2C%20euch%20geht%20es%20gut!%20K%C3%B6nntet%20ihr%20mir%20bitte%20einen%20Sch%C3%A4tzwert%20f%C3%BCr%20unsere%20Zeitungen%20nennen%3F%0AWir%20sind%20${classSize}%20Sch%C3%BCler*innen%20in%20der%20Stufe%20und%20freuen%20uns%20auf%20eure%20R%C3%BCckmeldung.%0A%0AAu%C3%9Ferdem%20h%C3%A4tte%20ich%20Interesse%20an%20euren%20Textilien%20%F0%9F%91%95%20und%20w%C3%BCrde%20mich%20freuen%2C%20falls%20ihr%20mir%20dazu%20weitere%20Informationen%20geben%20k%C3%B6nntet.`}
          />
        ) : (
          <ContactOnWhatsappButton
            messageId="v2 funnel continue whatsapp"
            text={`Hallo%20liebes%20nuggit-Team%20%F0%9F%91%8B%2C%20ich%20hoffe%2C%20es%20geht%20euch%20gut!%0AK%C3%B6nntet%20ihr%20mir%20bitte%20einen%20Sch%C3%A4tzwert%20f%C3%BCr%20unsere%20Zeitungen%20nennen%3F%20Wir%20sind%20${classSize}%20Sch%C3%BCler*innen%20in%20der%20Stufe%20und%20freuen%20uns%20auf%20eure%20R%C3%BCckmeldung.`}
          />
        )}
      </>
    ) : (
      <>
        {usingNuggit === "yes" ? (
          <ContactOnWhatsappButton
            messageId="v2 funnel continue whatsapp"
            text={`%0AHallo%20liebes%20nuggit-Team%20%F0%9F%91%8B%2C%0Aich%20hoffe%2C%20es%20geht%20euch%20gut!%20K%C3%B6nntet%20ihr%20mir%20bitte%20einen%20Sch%C3%A4tzwert%20f%C3%BCr%20unsere%20Abschluss-Textilien%20%F0%9F%91%95%20nennen%3F%0AWir%20sind%20${classSize}%20Sch%C3%BCler*innen%20in%20der%20Stufe%20und%20freuen%20uns%20auf%20eure%20R%C3%BCckmeldung.%0AUnsere%20B%C3%BCcher%20haben%20wir%20ja%20bereits%20mit%20euch%20geplant%2C%20deshalb%20sind%20wir%20jetzt%20sehr%20gespannt%20auf%20euer%20Angebot%20f%C3%BCr%20die%20Textilien.`}
          />
        ) : (
          <ContactOnWhatsappButton
            messageId="v2 funnel continue whatsapp"
            text={`Hallo%20liebes%20nuggit-Team%20%F0%9F%91%8B%2C%0Aich%20hoffe%2C%20es%20geht%20euch%20gut!%20K%C3%B6nntet%20ihr%20mir%20bitte%20einen%20Sch%C3%A4tzwert%20f%C3%BCr%20unsere%20Abschluss-Textilien%20%F0%9F%91%95%20%20nennen%3F%0AWir%20sind%20${classSize}%20Sch%C3%BCler*innen%20in%20der%20Stufe%20und%20freuen%20uns%20auf%20eure%20R%C3%BCckmeldung.`}
          />
        )}
      </>
    )}

    <Divider stackBlockGap="NO" withOr={true} />

    {isAuthenticated ? (
      <>
        <Paragraph>
          <FormattedMessage id="v2 funnel success back to dashboard" />
        </Paragraph>
        <Link to={ROUTE_ACCOUNT_DASHBOARD}>
          <ButtonBlock background="PRIMARY" color="WHITE">
            <FormattedMessage id="Back to Dashboard" />
          </ButtonBlock>
        </Link>
      </>
    ) : (
      <>
        <Paragraph>
          <FormattedMessage id="v2 funnel success back to sign up" />
        </Paragraph>
        <Link to={ROUTE_SIGNUP}>
          <ButtonBlock background="PRIMARY" color="WHITE">
            <FormattedMessage id="Back to signup" />
          </ButtonBlock>
        </Link>
      </>
    )}

    {type === 'bookfunnel' ? (
      <ImageBlock fullWidth={true}>
        <img src="/images/funnels/app.jpg" width="100%" alt="" />
      </ImageBlock>
    ) : (
      <ImageBlock fullWidth={true}>
        <img src="/images/funnels/app_textile.png" width="100%" alt="" />
      </ImageBlock>
    )}
  </PageStackBlock>
);

export default SuccessScreen;
