export const ROUTE_HOME = '/';


export const ROUTE_SIGNUP = '/signup';
export const ROUTE_SIGNUP_WITH_AFFILIATE_TOKEN =
ROUTE_SIGNUP + '/:affiliate_token';
export const ROUTE_JOIN = '/join';
export const ROUTE_SUPPORT = '/support';

export const ROUTE_FUNNELS = '/funnels';
export const ROUTE_TRAVEL_FUNNEL = '/funnels/travel';
export const ROUTE_CLOTHING_FUNNEL = '/funnels/clothing';
export const ROUTE_BOOK_FUNNEL = '/funnels/book';
export const ROUTE_BOOK_FUNNEL_V2 = '/funnels/v2/book';
export const ROUTE_TEXTILE_FUNNEL_V2 = '/funnels/v2/clothing';

export const ROUTE_INFO_PACKAGE_FUNNEL = '/funnels/info_package';
export const ROUTE_INFO_PACKAGE_TEXTILES_FUNNEL = '/funnels/info_package_textiles';

export const ROUTE_LOGIN_PAGE = '/login';
export const ROUTE_LOGIN = ROUTE_LOGIN_PAGE + '?auth=unauthorized';
export const ROUTE_LOGIN_FAILED = ROUTE_LOGIN_PAGE + '?auth=failed';
export const ROUTE_LOGOUT = ROUTE_LOGIN_PAGE + '?auth=logout';
export const ROUTE_AUTH_ERROR = ROUTE_LOGIN_PAGE + '?auth=error';
export const ROUTE_UNCONFIRMED = ROUTE_LOGIN_PAGE + '?auth=unconfirmed';

export const ROUTE_AUTH = '/app';
export const ROUTE_HELP = ROUTE_AUTH + '/help';

// Account Dashboard
export const ROUTE_ACCOUNT_DASHBOARD = ROUTE_AUTH + '/account';
export const ROUTE_ACCOUNT_DASHBOARD_ACCOUNT = ROUTE_AUTH + '/account/settings';
export const ROUTE_ACCOUNT_MOBILE_NUMBER = ROUTE_AUTH + '/account/mobile_number';
export const ROUTE_ACCOUNT_CHANGE_PASSWORD = ROUTE_AUTH + '/account/password';

// Book
export const ROUTE_BOOK = ROUTE_AUTH + '/book';;
export const ROUTE_DASHBOARD = ROUTE_BOOK;
export const ROUTE_ONBOARDING = ROUTE_BOOK + '/onboarding';
export const ROUTE_DISCOUNTS = ROUTE_BOOK + '/discounts';
export const ROUTE_CONTENT = ROUTE_BOOK + '/content';
export const ROUTE_PDF_PREVIEW = ROUTE_CONTENT + '/preview';

export const ROUTE_SETTINGS = ROUTE_BOOK + '/settings';
export const ROUTE_SET_GROUP = ROUTE_SETTINGS + '/setup/group';
export const ROUTE_SET_TEAMS = ROUTE_SETTINGS + '/setup/teams';

export const ROUTE_CALCULATOR = ROUTE_BOOK + '/calculator';

export const ROUTE_DELIVERY_DATES_OVERVIEW = ROUTE_BOOK + '/delivery_dates';

export const ROUTE_MOTTOS = ROUTE_BOOK + '/motto';
export const ROUTE_MOTTOS_ORG = ROUTE_MOTTOS + '/manage';

export const ROUTE_FACTSHEETS = ROUTE_BOOK + '/factsheets';
export const ROUTE_FACTSHEETS_ORG = ROUTE_FACTSHEETS + '/all';

export const ROUTE_COMMENTS = ROUTE_BOOK + '/comments';
export const ROUTE_COMMENTS_ORG = ROUTE_COMMENTS + '/all';

export const ROUTE_TEXTS = ROUTE_BOOK + '/texts';
export const ROUTE_QUOTES = ROUTE_BOOK + '/quotes';
export const ROUTE_RANKINGS = ROUTE_BOOK + '/rankings';
export const ROUTE_SPONSORS = ROUTE_BOOK + '/sponsors';
export const ROUTE_SPONSOR_DETAILS = ROUTE_SPONSORS + '/detail';
export const ROUTE_SPONSOR_CHAPTER = ROUTE_SPONSORS + '/:chapterId';
export const ROUTE_SPONSOR_SETTINGS = ROUTE_SPONSORS + '/:chapterId/settings';
export const ROUTE_SPONSOR_PAGES = ROUTE_SPONSORS + '/:chapterId/pages';
export const ROUTE_SPONSOR_PLACEHOLDER_PAGES = ROUTE_SPONSORS + '/:chapterId/placeholder_pages';
export const ROUTE_SPONSOR_PAGE = ROUTE_SPONSOR_PAGES + '/:id';
export const ROUTE_SPONSOR_PLACEHOLDER_PAGE = ROUTE_SPONSOR_PLACEHOLDER_PAGES + '/:id';
export const ROUTE_NEW_SPONSOR_PAGE = ROUTE_SPONSOR_PAGES + '/new';

export const ROUTE_ALBUMS = ROUTE_BOOK + '/albums';
export const ROUTE_ALBUM = ROUTE_ALBUMS + '/:chapterId';
export const ROUTE_ALBUM_SETTINGS = ROUTE_ALBUMS + '/:chapterId/settings';
export const ROUTE_ALBUM_NEW_PHOTO = ROUTE_ALBUMS + '/:chapterId/new';
export const ROUTE_ALBUM_PHOTO = ROUTE_ALBUMS + '/:chapterId/:id';

export const ROUTE_ALBUM_PAGES = ROUTE_ALBUMS + '/:chapterId/pages';
export const ROUTE_NEW_ALBUM_PAGE = ROUTE_ALBUM_PAGES + '/new';
export const ROUTE_ALBUM_PAGE = ROUTE_ALBUM_PAGES + '/:id';

export const ROUTE_YEARBOOKS = ROUTE_BOOK + '/yearbook';
export const ROUTE_YEARBOOK = ROUTE_YEARBOOKS + '/:chapterId';

export const ROUTE_TOC = ROUTE_BOOK + '/toc';

export const ROUTE_LAYOUT = ROUTE_BOOK + '/layout';
export const ROUTE_BACK_COVER = ROUTE_LAYOUT + '/back';

export const ROUTE_DELIVERY_DATE = ROUTE_BOOK + '/delivery_date'; // -v2-
// export const ROUTE_CHOOSE_DELIVERY_DATE = ROUTE_DELIVERY_DATE + '/choose'; // -v2-
// export const ROUTE_CONFIRM_DELIVERY_DATE = ROUTE_DELIVERY_DATE + '/confirm'; // -v2-

export const ROUTE_TIMEPLAN = ROUTE_BOOK + '/timeplan';
export const ROUTE_CHOOSE_TIMEPLAN = ROUTE_TIMEPLAN + '/choose';

export const ROUTE_CHECKOUT = ROUTE_BOOK + '/checkout';
export const ROUTE_CHECKOUT_CHANGE_AUTHOR =
  ROUTE_BOOK + '/checkout_change_author';
export const ROUTE_OFFER = ROUTE_BOOK + '/offer';
export const ROUTE_ORDER = ROUTE_BOOK + '/order';
export const ROUTE_ORDER_OVERVIEW = ROUTE_BOOK + '/order_overview';

// Textiles
export const ROUTE_TEXTILE = ROUTE_AUTH + "/textile_order";
export const ROUTE_TEXTILE_ORDER = ROUTE_AUTH + "/textile_order/:textileOrderId";


// Textiles Onboarding
export const ROUTE_TEXTILE_ONBOARDING = ROUTE_TEXTILE_ORDER + "/onboarding";

// Textile Management
export const ROUTE_TEXTILE_MANAGEMENT = ROUTE_TEXTILE_ORDER + "/management";


// TEXTILE ROUTES
export const ROUTE_TEXTILE_DESIGN = ROUTE_TEXTILE_ORDER + '/textile_design'
export const ROUTE_TEXTILE_DESIGN_SETTINGS = ROUTE_TEXTILE_DESIGN +  '/settings'
export const ROUTE_TEXTILE_DESIGN_BACK = ROUTE_TEXTILE_DESIGN + '/back'

//  TEXTILE GROUPS ROUTES
export const ROUTE_TEXTILE_DESIGN_GROUPS = ROUTE_TEXTILE_ORDER  + '/groups';

// textile calculator
export const ROUTE_TEXTILE_CALCULATOR = ROUTE_TEXTILE_ORDER + '/calculator';

// textile checkout
export const ROUTE_TEXTILE_CHECKOUT = ROUTE_TEXTILE_ORDER + '/checkout';

// Textile Profiles
export const ROUTE_TEXTILE_PROFILES = ROUTE_TEXTILE_ORDER + "/my_profile";

// textile order
export const ROUTE_TEXTILE_ORDER_OVERVIEW= ROUTE_TEXTILE_ORDER + "/order"
export const ROUTE_TEXTILE_ORDER_PLACED_OVERVIEW= ROUTE_TEXTILE_ORDER + "/order/placed"

// textile preorder
export const ROUTE_TEXTILE_PREORDER = ROUTE_TEXTILE_ORDER + "/preorder"

// preorder info
export const ROUTE_PREORDER_PROCESS = ROUTE_TEXTILE_ORDER + "/how-it-works"

// timeplan

export const ROUTE_TEXTILE_TIMEPLAN = ROUTE_TEXTILE_ORDER + "/timeplan"

// layout preview
export const ROUTE_LAYOUT_PREVIEW = '/layout-preview/:type';
