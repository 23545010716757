import React from 'react';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import FONT_SIZES from 'utils/constants/fontSizes';
import FONT_WEIGHTS from 'utils/constants/fontWeights';
import SPACES from 'utils/constants/spaces';

interface DividerProps {
  stackBlockGap?: keyof typeof SPACES;
  useStackBlockGap?: boolean;
  color?: keyof typeof COLORS;
  withOr?: boolean; // new prop
}

const DividerLine = styled.hr<
  Pick<DividerProps, 'stackBlockGap' | 'useStackBlockGap' | 'color'>
>`
  margin: ${({ useStackBlockGap, stackBlockGap }) =>
    useStackBlockGap
      ? '0'
      : `calc(${SPACES.XL} - ${
          stackBlockGap ? SPACES[stackBlockGap] : SPACES.L
        }) 0`};
  border: none;
  border-top: 1px solid
    ${({ color }) => (color ? COLORS[color] : COLORS.GRAY900)};
`;

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const DividerText = styled.span<Pick<DividerProps, 'color'>>`
  padding: 0 ${SPACES.XS};
  color: ${({ color }) => (color ? COLORS[color] : COLORS.BLACK)};
  white-space: nowrap;
  font-size: ${FONT_SIZES.XS};
  font-weight: ${FONT_WEIGHTS.BOLD};
`;

const Divider: React.FC<DividerProps> = ({
  stackBlockGap,
  useStackBlockGap,
  color,
  withOr
}) => {
  if (withOr) {
    return (
      <DividerContainer>
        <DividerLine color={color} style={{ flex: 1, margin: 0 }} />
        <DividerText color={color}>ODER</DividerText>
        <DividerLine color={color} style={{ flex: 1, margin: 0 }} />
      </DividerContainer>
    );
  }

  return (
    <DividerLine
      stackBlockGap={stackBlockGap}
      useStackBlockGap={useStackBlockGap}
      color={color}
    />
  );
};

export default Divider;
