import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import FooterWrapper from 'blocks/FooterBlock/FooterWrapper';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Logo from 'components/HeaderComponent/Logo';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';
import config from 'config';
import BackClickLink from 'domain/Links/BackClickLink';
import { useHistory } from 'react-router';

const FunnelV2Page = ({
  children,
  backToSource,
  fromDashboard,
  showBackButton
}: {
  children: ReactNode;
  backToSource?: boolean;
  fromDashboard?: string | undefined;
  showBackButton?: boolean;
}) => {
  const history = useHistory();

  return (
    <FooterWrapper>
      <TopActionBlock progressBarMargin={true}>
        <HorizontalStackBlock justified={true}>
          {showBackButton && (
            <>
              {backToSource ? (
                fromDashboard ? (
                  <BackClickLink
                    onClick={() => history.replace(fromDashboard)}
                    messageId="Back to dashboard long"
                  />
                ) : (
                  <a href={config.websiteUrl}>
                    <TextElement color="GRAY10">
                      <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
                      <FormattedMessage id="to homepage" />
                    </TextElement>
                  </a>
                )
              ) : (
                <BackClickLink
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                  }}
                />
              )}
            </>
          )}

          <Logo />
        </HorizontalStackBlock>
      </TopActionBlock>

      {children}
    </FooterWrapper>
  );
};

export default FunnelV2Page;
