import React from 'react';

import ProgressbarBlock from 'blocks/ProgressbarBlock';
import COLORS from 'utils/constants/colors';
import { FONT_SIZES } from 'utils/constants/fontSizes';

interface ProgressbarComponentProps {
  currentPage: number;
  totalPages: number;
}

function FunnelProgressbarComponent({
  currentPage,
  totalPages
}: ProgressbarComponentProps) {
  const progressStatusLabel = (currentPage / (totalPages + 1)) * 100;
  const clampedProgress = Math.max(Math.min(progressStatusLabel, 85), 15);

  const LABEL_WIDTH_REM = 5;
  const LABEL_HALF = LABEL_WIDTH_REM / 2;

  const progressText = `Frage ${currentPage}/${totalPages}`;

  return (
    <ProgressbarBlock isFunnel={true}>
      <svg width="100%" height="20">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect
            fill={COLORS.GRAY950}
            width="100%"
            height="0.25rem"
            y="calc(50% - 0.125rem)"
          />

          <rect
            fill={COLORS.PRIMARY}
            width={clampedProgress === 85 ? '100%' : `${clampedProgress}%`}
            height="0.25rem"
            y="calc(50% - 0.125rem)"
          />

          <rect
            x={0}
            width={`${LABEL_WIDTH_REM}rem`}
            height="100%"
            fill={COLORS.PRIMARY}
            rx="3"
            ry="3"
            style={{
              transform: `translateX(calc(${clampedProgress}% - ${LABEL_HALF}rem))`
            }}
          />

          <g
            style={{
              transform: `translateX(${clampedProgress}%)`
            }}
          >
            <text
              x={0}
              y="50%"
              dy="0.05rem"
              fill={COLORS.WHITE}
              fontSize={FONT_SIZES.XS}
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {progressText}
            </text>
          </g>
        </g>
      </svg>
    </ProgressbarBlock>
  );
}

export default FunnelProgressbarComponent;
