import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import Divider from 'components/Divider/Divider';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import ContactOnWhatsappButton from 'domain/Buttons/ContactOnWhatsappButton';
import { Link } from 'react-router-dom';

const ContinueScreen = ({
  nextScreen,
  type,
  classSize
}: {
  nextScreen: string;
  type: 'bookfunnel' | 'textilefunnel';
  classSize: number;
}) => (
  <PageStackBlock>
    <PageHeader
      headline={<FormattedMessage id="v2 funnel continue headline" />}
      text={<FormattedMessage id="v2 funnel continue info" />}
    />

    {type == 'bookfunnel' ? (
      <ContactOnWhatsappButton
        messageId="v2 funnel continue whatsapp"
        text={`Hallo%20liebes%20nuggit-Team%20%F0%9F%91%8B%2C%20ich%20hoffe%2C%20es%20geht%20euch%20gut!%0AK%C3%B6nntet%20ihr%20mir%20bitte%20einen%20Sch%C3%A4tzwert%20f%C3%BCr%20unsere%20Zeitungen%20nennen%3F%20Wir%20sind%20${classSize}%20Sch%C3%BCler*innen%20in%20der%20Stufe%20und%20freuen%20uns%20auf%20eure%20R%C3%BCckmeldung.`}
      />
    ) : (
      <ContactOnWhatsappButton
        messageId="v2 funnel continue whatsapp"
        text={`Hallo%20liebes%20nuggit-Team%20%F0%9F%91%8B%2C%0Aich%20hoffe%2C%20es%20geht%20euch%20gut!%20K%C3%B6nntet%20ihr%20mir%20bitte%20einen%20Sch%C3%A4tzwert%20f%C3%BCr%20unsere%20Abschluss-Textilien%20%F0%9F%91%95%20%20nennen%3F%0AWir%20sind%20${classSize}%20Sch%C3%BCler*innen%20in%20der%20Stufe%20und%20freuen%20uns%20auf%20eure%20R%C3%BCckmeldung.`}
      />
    )}

    <Divider stackBlockGap="NO" withOr={true}/>

    <Paragraph>
      <FormattedMessage id="v2 funnel continue info 2" />
    </Paragraph>
    <Link to={`?screen=${nextScreen}`}>
      <ButtonBlock background="PRIMARY" color="WHITE">
        <FormattedMessage id="Next" />
      </ButtonBlock>
    </Link>
  </PageStackBlock>
);

export default ContinueScreen;
