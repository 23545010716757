import styled from 'styled-components';

import COLORS from 'utils/constants/colors';
import SPACES from 'utils/constants/spaces';

interface TopActionBlockProps {
  progressBarMargin?: boolean;
}

const TopActionBlock = styled.div<TopActionBlockProps>`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  margin-top: ${SPACES.S};
  margin-bottom: ${SPACES.XL};

  a {
    font-size: 0.875em;
    font-weight: 600;
    letter-spacing: 0;
    color: ${COLORS.GRAY10};
  }

  ${(props) =>
    props.progressBarMargin &&
    `
    margin-bottom: calc(${SPACES.XL} + 1rem);
  `}
`;

export default TopActionBlock;
