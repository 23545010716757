interface imageOffsets {
  top: string;
  left: string;
  height: string;
  width: string;
}

export const layoutImageOffsetsByTextile = (
  textileType: string,
  side: 'front' | 'back'
): imageOffsets => {
  switch (textileType) {
    case 'classicCollegeHoodie':
      if (side === 'front') {
        return {
          top: '23.08%',
          left: '26.30%',
          height: '34.50%',
          width: '47.40%'
        };
      } else {
        return {
          top: '24.36%',
          left: '26.3%',
          height: '55.06%',
          width: '47.38%'
        };
      }
    case 'premiumOversizedHoodie':
      if (side === 'front') {
        return {
          top: '21.74%',
          left: '32.23%',
          height: '27.98%',
          width: '38.36%'
        };
      } else {
        return {
          top: '21.91%',
          left: '30.78%',
          height: '44.61%',
          width: '38.38%'
        };
      }
    case 'classicShirt':
      if (side === 'front') {
        return {
          top: '18.62%',
          left: '29.32%',
          height: '31.26%',
          width: '42.86%'
        };
      } else {
        return {
          top: '16.51%',
          left: '28.63%',
          height: '49.82%',
          width: '42.86%'
        };
      }
    default:
      return {
        top: '0',
        left: '0',
        height: '100%',
        width: '100%'
      };
  }
};
