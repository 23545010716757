import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import NakedButton from 'elements/NakedButton';
import SearchListInput from 'elements/SearchListInput';
import { SchoolModelType } from 'models/SchoolModel';
import { FormType } from 'utils/hooks/useForm';

const SignupSchoolSelectScreen = ({
  form,
  schools,
  backRoute,
  selectSchool
}: {
  form: FormType;
  schools: SchoolModelType[];
  backRoute: string;
  selectSchool: (id: number, name: string) => void;
}) => {
  // useEffect(() => {
  //   form.setField('filter', '');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {schools.length > 5 ? (
        <SignupHeaderComponent
          backRoute={backRoute}
          progressStatus={(100 / 10) * 4}
        />
      ) : (
        <SignupHeaderComponent
          backRoute={backRoute}
          progressStatus={(100 / 10) * 4}
        />
      )}

      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="signup choose your school" />}
          text={<FormattedMessage id="signup school select" />}
        />

        <SearchListInput form={form} placeholderId="signup find school" />

        <>
          <ListStackBlock>
            {schools.length > 0 ? (
              <>
                {schools.map((school) => (
                  <NakedButton
                    key={school.id}
                    onClick={() => selectSchool(school.id, school.name)}
                  >
                    <EveryCard>
                      <EveryCardPadding>
                        <EveryCardBody>
                          <EveryCardHeadline>{school.name}</EveryCardHeadline>
                          <Paragraph size="S" color="TEXT_LIGHT">
                            {school.school_type}
                          </Paragraph>
                        </EveryCardBody>
                      </EveryCardPadding>
                    </EveryCard>
                  </NakedButton>
                ))}
              </>
            ) : (
              <Paragraph size="S" color="TEXT_LIGHT">
                <FormattedMessage id="No school found" />
              </Paragraph>
            )}
          </ListStackBlock>
          <Link to="?screen=add_school">
            <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK">
              <FormattedMessage id="signup add school" />
            </ButtonBlock>
          </Link>
        </>
      </PageStackBlock>
    </>
  );
};

export default SignupSchoolSelectScreen;
