import { post } from "api/BaseApi";

export const submitInitialFunnel = (formData: {
  [key: string]: string | string[] | number | number[] | boolean;
}) => {
  return post('/funnels/submit_initial_funnel', formData);
}

export const submitAdditionalFunnel = (formData: {
  [key: string]: string | string[] | number | number[] | boolean;
}) => {
  return post('/funnels/submit_additional_funnel', formData);
}

