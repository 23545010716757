import React from 'react';
import { FormattedMessage } from 'react-intl';

import CheckboxBlock from 'blocks/CheckboxBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TrustSymbolsComponent from 'components/TrustSymbolsComponent';
import InputMessage from 'elements/InputMessage';
import { intl } from 'i18n';
import NextButton from 'screens/funnels/NextButton';
import COLORS from 'utils/constants/colors';
import { FormType } from 'utils/hooks/useForm';

const PersonalDataScreen = ({
  form,
  onSubmit
}: {
  form: FormType;
  onSubmit: () => void;
}) => (
  <PageStackBlock>
    <PageHeader
      headline={<FormattedMessage id="v2 funnel personal data headline" />}
      text={<FormattedMessage id="v2 funnel personal data info" />}
    />

    <FormStackBlock>
      <TextInputComponent
        name="first_name"
        label={<FormattedMessage id="Your name" />}
        {...form.bindInput('first_name')}
        autoFocus={true}
      />

      <TextInputComponent
        type="tel"
        name="mobile_number"
        label={<FormattedMessage id="Your number" />}
        autoComplete="tel"
        {...form.bindInput('mobile_number')}
        placeholder={intl.formatMessage({ id: 'phone number placeholder' })}
      />

      <CheckboxBlock>
        <CheckboxBlock.CheckboxElement
          id="allow_contact"
          {...form.bindCheckbox('allow_contact')}
        />
        <CheckboxBlock.LabelElement htmlFor="allow_contact">
          <FormattedMessage id="v2 funnel personal data confirm whatsapp" />
        </CheckboxBlock.LabelElement>
        {form.errors.allow_contact && (
          <InputMessage error={true} style={{
            color: `${COLORS.BLACK}`,
            backgroundColor: `${COLORS.RED_LIGHT}`,
          }}>{form.errors.allow_contact}</InputMessage>
        )}
      </CheckboxBlock>
    </FormStackBlock>

    <NextButton condition={!form.loading} onClick={onSubmit}>
      <FormattedMessage id="Next" />
    </NextButton>
    <TrustSymbolsComponent />
  </PageStackBlock>
);

export default PersonalDataScreen;
