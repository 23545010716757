import React from 'react';

import CheckboxBlock from 'blocks/CheckboxBlock';

export const ProgressBoxItem = ({
  checked,
  children
}: {
  checked?: boolean;
  children?: any;
}) => (
  <CheckboxBlock>
    <CheckboxBlock.CheckboxElement readOnly={true} checked={checked} />
    <CheckboxBlock.LabelElement bigger={false} lineThrough={checked}>{children}</CheckboxBlock.LabelElement>
  </CheckboxBlock>
);

export default ProgressBoxItem;
