import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import ItemStackBlock, {
  ItemStackProps
} from 'blocks/ItemStackBlock/ItemStackBlock';
import WideBlock from 'blocks/WideBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import Headline from 'elements/Headline';
import UppercaseHeading from 'elements/UppercaseHeading';
import WordWrap from 'elements/WordWrap';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChapterModelType } from 'models/ChapterModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import history from 'utils/history';

import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import SilverBannerComponent from 'components/SilverBannerComponent';
import TextElement from 'components/TextElement/TextElement';
import ChapterDescriptionComponent from './ChapterDescriptionComponent';

interface ChapterPreviewComponentProps {
  chapter?: ChapterModelType;
  onChapterUpdated?: (chapter: ChapterModelType) => void;

  defaultTextId?: string;
  baseRoute: string;

  organizersOnly?: boolean;
  notEditable?: boolean;
  noEditingState?: boolean;
  noSettings?: boolean;
  noLayoutEditor?: boolean;

  marginBottom?: ItemStackProps['marginBottom'];
  children?: any;

  applicationStore?: ApplicationStoreType;
  chaptersStore?: ChaptersStoreType;
}

interface ChapterPreviewComponentState {
  chapterUpdatingState?: 'loading' | 'error';
}

@inject('applicationStore', 'chaptersStore')
@observer
class ChapterPreviewComponent extends React.Component<
  ChapterPreviewComponentProps,
  ChapterPreviewComponentState
> {
  state: ChapterPreviewComponentState = {};

  private goToSettings() {
    const { baseRoute, chapter } = this.props;
    if (!chapter) {
      return;
    }

    history.push(baseRoute + '/' + chapter.id + '/settings');
  }

  private goToLayoutEditor() {
    const { baseRoute, chapter } = this.props;
    if (!chapter) {
      return;
    }

    history.push(
      baseRoute + '/' + chapter.id + '/settings?active=layout&from=list'
    );
  }

  private async toggleChanged(isChecked: boolean) {
    const { chaptersStore, chapter, onChapterUpdated } = this.props;

    if (!chapter || !onChapterUpdated) {
      return;
    }

    this.setState({
      chapterUpdatingState: 'loading'
    });

    try {
      const updatedChapter = await chaptersStore!.updateChapter(chapter.id, {
        editing_state: isChecked ? 'active' : 'closed'
      });

      updatedChapter && onChapterUpdated(updatedChapter);

      this.setState({
        chapterUpdatingState: undefined
      });
    } catch (error: any) {
      this.setState({
        chapterUpdatingState: 'error'
      });
    }
  }

  private renderEditingState(mayEdit: boolean) {
    const { chapter, onChapterUpdated, organizersOnly } = this.props;
    const { chapterUpdatingState } = this.state;

    if (
      organizersOnly ||
      !chapter ||
      chapter.access_level === 'organizer' ||
      !onChapterUpdated
    ) {
      return null;
    }

    const isOn = chapter.editing_state === 'active';

    return (
      <FormStackBlock>
        {chapterUpdatingState === 'loading' && <LoadingOverlayComponent />}
        {chapterUpdatingState === 'error' && <GenericErrorComponent />}

        <CustomCheckboxBlock>
          <CustomCheckboxBlock.CheckboxElement
            disabled={!mayEdit}
            checked={isOn}
            onChange={(e: any) => this.toggleChanged(e.target.checked)}
          />
          <CustomCheckboxBlock.LabelElement>
            <FormattedMessage
              id={isOn ? 'editing toggle on' : 'editing toggle off'}
            />
            <TextElement>
              <FormattedMessage
                id={isOn ? 'editing toggle on info' : 'editing toggle off info'}
              />
            </TextElement>
          </CustomCheckboxBlock.LabelElement>
        </CustomCheckboxBlock>
      </FormStackBlock>
    );
  }

  render() {
    const {
      applicationStore,
      chapter,
      defaultTextId,
      organizersOnly,
      notEditable,
      noSettings,
      noLayoutEditor,
      noEditingState,
      marginBottom,
      children
    } = this.props;

    const header = (
      <Headline.Large>
        <WordWrap>
          {chapter?.title ? (
            chapter.title
          ) : !defaultTextId ? null : (
            <FormattedMessage id={defaultTextId} />
          )}
        </WordWrap>
      </Headline.Large>
    );

    const access = chapter && (
      <TextElement>
        {organizersOnly || chapter.access_level === 'organizer' ? (
          <>
            <IconComponent icon="ORGANIZER" />{' '}
            <FormattedMessage id="role label organizer" />
          </>
        ) : chapter.group ? (
          <>
            <IconComponent icon="USER" /> {chapter.group.name}
          </>
        ) : (
          <>
            <IconComponent icon="USERGROUP" />{' '}
            <FormattedMessage id="role label student" />
          </>
        )}
      </TextElement>
    );

    const headerAndAccess = (
      <ItemStackBlock gap="XS">
        {header}
        {access}
      </ItemStackBlock>
    );

    if (!applicationStore!.isOrganizer || !chapter) {
      return (
        <ItemStackBlock gap="M" marginBottom={marginBottom}>
          {headerAndAccess}

          <ChapterDescriptionComponent chapter={chapter} />
        </ItemStackBlock>
      );
    }

    const mayEdit =
      !notEditable && applicationStore!.isChapterEditAllowed(chapter);

    const book = applicationStore!.book;

    if (!book) {
      return null;
    }

    return (
      <PageStackBlock>
        {headerAndAccess}

        {!noSettings && mayEdit && (
          <ItemStackBlock gap="M">
            <ButtonBlock
              inline={true}
              slim={true}
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              onClick={() => this.goToSettings()}
            >
              <IconComponent icon="settings" />
              <FormattedMessage id="Settings" />
            </ButtonBlock>
          </ItemStackBlock>
        )}

        {!noEditingState && this.renderEditingState(mayEdit)}

        <WideBlock padded={true}>
          <ItemStackBlock gap="L">
            <ItemStackBlock gap="S">
              {!!children && (
                <UppercaseHeading>
                  <FormattedMessage id="Design preview" />
                </UppercaseHeading>
              )}

              {children}
            </ItemStackBlock>
            {mayEdit && !noLayoutEditor && (
              <ButtonBlock
                inline={true}
                slim={true}
                background="PRIMARY_LIGHT"
                color="PRIMARY_DARK"
                onClick={() => this.goToLayoutEditor()}
              >
                <IconComponent icon="brush" size={1} />
                <FormattedMessage id="Layout editor" />
              </ButtonBlock>
            )}

            { book.price_tier == "silver" && <SilverBannerComponent /> }
          </ItemStackBlock>
        </WideBlock>

        {!applicationStore!.isOrganizer && (
          <ChapterDescriptionComponent
            chapter={chapter}
            organizerHeader={true}
          />
        )}
      </PageStackBlock>
    );
  }
}

export default ChapterPreviewComponent;
