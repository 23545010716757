import { get } from "api/BaseApi";

interface Account {
  id: number;
  mobile_number: string
}

export const getCheckAuthenticated = (useRandomParameter = true): Promise<Account> => {
  let param = '';
  if (useRandomParameter) {
    param = '?r=' + Math.floor(Math.random() * 899999 + 100000);
  }
  return get('/session' + param)
}
