// import React, { Component } from 'react';

import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import config from 'config';
import { useCheckAuthenticated } from 'queries/app/useCheckAuthenticated';
import {
  useSendAdditionalFunnel,
  useSendInitalFunnel
} from 'queries/app/useFunnels';
import React, { useEffect } from 'react';
import { finishFunnel, funnelSite } from 'utils/analytics';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import { local } from 'utils/storage';
import FunnelProgressbarComponent from './component/FunnelProgressBar';
import ClassSizeScreen from './screens/ClassSizeScreen';
import ContinueScreen from './screens/ContinueScreen';
import OfferBooksScreen from './screens/OfferBooksScreen';
import PersonalDataScreen from './screens/PersonalDataScreen';
import RoleScreen from './screens/RoleScreen';
import SuccessScreen from './screens/SuccessScreen';
import UserOriginsScreen from './screens/UserOriginsScreen';
import YearScreen from './screens/YearScreen';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TransitionWrapper } from './BookFunnelScreenV2';
import FunnelV2Page from './component/FunnelV2Page';
const SCREENS = [
  'year',
  'class_size',
  'personal_data',
  'continue',
  'role',
  'offer_textiles',
  'user_origins',
  'success_screen'
] as const;
type Screen = typeof SCREENS[number];
const screenUrl = (screen: Screen) => `?screen=${screen}`;

const DEFAULT_SIGN_UP_REF = 'servusGrussDi';

interface FormProps {
  form: FormType;
}

interface LeadLogId {
  lead_log_id: string;
}

const TextileFunnelScreenV2: React.FC<HistoryProps & FormProps> = (props) => {
  const [screen, setScreen] = React.useState<Screen>(SCREENS[0]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [transitionDirection, setTransitionDirection] = React.useState<
    'forward' | 'backward'
  >('forward');
  const [currentLead, setCurrentLead] = React.useState<string>('');
  const checkAuthenticated = useCheckAuthenticated();
  const initialFunnel = useSendInitalFunnel();
  const additionalFunnel = useSendAdditionalFunnel();
  const prevPageRef = React.useRef<number>(1);

  const setRef = () => {
    const { query } = props.location;

    const old_ref = local.get(config.signupRefName);
    // Get ref from query string
    const ref = query?.signupRef?.trim();

    if (old_ref) {
      local.set(config.signupRefName, DEFAULT_SIGN_UP_REF);
    }

    if (ref) {
      local.set(config.signupRefName, ref);
    } else {
      local.set(config.signupRefName, old_ref);
    }
  };

  useEffect(() => {
    setRef();
    const { location } = props;

    if (location.query && SCREENS.includes(location.query.screen as Screen)) {
      const newScreen = location.query.screen as Screen;
      const newPage = SCREENS.indexOf(newScreen) + 1;

      // Determine the direction by comparing with the previous page
      if (newPage > prevPageRef.current) {
        setTransitionDirection('forward');
      } else if (newPage < prevPageRef.current) {
        setTransitionDirection('backward');
      }

      setScreen(newScreen);
      setCurrentPage(newPage);
      // Update our ref for the next comparison
      prevPageRef.current = newPage;
    }

    if (props.form.values.init === false) {
      props.history.replace(screenUrl(SCREENS[0]));
      props.form.setField('init', true);
    }

    funnelSite('textile');
  }, [props.location]);

  if (checkAuthenticated.isLoading) {
    return <LoadingOverlayComponent />;
  }

  const decidePath = () => {
    const { history } = props;
    if (checkAuthenticated.data) {
      finishFunnel('textile', checkAuthenticated.data.mobile_number);
      sendInitialFunnel();
    } else {
      if (!props.form.values.class_size) {
        props.form.setError('class_size', 'Muss ausgefüllt werden');
        return;
      }
      history.push(screenUrl('personal_data'));
    }
  };

  const sendInitialFunnel = async () => {
    const { history, form } = props;
    form.resetErrors();

    const funnelRef = local.get(config.signupRefName);
    finishFunnel('textile', form.values.mobile_number);
    initialFunnel.mutate(
      { ...props.form.values, funnel_ref: funnelRef },
      {
        onSuccess: (data) => {
          const leadLogData = data as LeadLogId;
          setCurrentLead(leadLogData.lead_log_id);
          history.push(screenUrl('continue'));
        },
        onError: (error) => {
          const formError = error as Error;
          handleFormError(props.form, formError);
        }
      }
    );
  };

  const sendAdditionalFunnel = async () => {
    const { history, form } = props;
    form.resetErrors();

    const funnelRef = local.get(config.signupRefName);
    additionalFunnel.mutate(
      { ...props.form.values, funnel_ref: funnelRef, lead_log_id: currentLead },
      {
        onSuccess: () => {
          history.push(screenUrl('success_screen'));
        },
        onError: (error) => {
          const formError = error as Error;
          handleFormError(props.form, formError);
        }
      }
    );
  };

  const setOfferBooks = (value: string) => {
    const { history, form } = props;
    form.setField('using_nuggit', value);
    history.push(screenUrl('user_origins'));
  };

  const chooseRole = () => {
    const { history, form } = props;
    if (!form.values.roles || form.values.roles === '') {
      form.setError('roles', 'Muss ausgefüllt werden');
    } else {
      form.resetErrors();
      history.push(screenUrl('offer_textiles'));
    }
  };

  return (
    <FunnelV2Page
      showBackButton={!(currentPage === 4 || currentPage === 8)}
      backToSource={currentPage === 1}
      fromDashboard={props.location.state?.fromDashboard}
    >
      {currentPage != SCREENS.length && (
        <FunnelProgressbarComponent
          currentPage={currentPage}
          totalPages={SCREENS.length}
        />
      )}
      <TransitionGroup>
        <CSSTransition
          key={screen}
          classNames={transitionDirection}
          timeout={300}
        >
          <TransitionWrapper>
            {screen === 'year' && (
              <YearScreen form={props.form} nextScreen="class_size" />
            )}
            {screen === 'class_size' && (
              <ClassSizeScreen
                form={props.form}
                onSubmit={() => decidePath()}
              />
            )}
            {screen === 'personal_data' && (
              <PersonalDataScreen
                form={props.form}
                onSubmit={() => sendInitialFunnel()}
              />
            )}
            {screen === 'continue' && (
              <ContinueScreen
                nextScreen="role"
                type="textilefunnel"
                classSize={props.form.values.class_size}
              />
            )}
            {screen === 'role' && (
              <RoleScreen onSubmit={() => chooseRole()} form={props.form} />
            )}
            {screen === 'offer_textiles' && (
              <OfferBooksScreen onSubmit={(value) => setOfferBooks(value)} />
            )}
            {screen === 'user_origins' && (
              <UserOriginsScreen
                form={props.form}
                onSubmit={() => sendAdditionalFunnel()}
              />
            )}
            {screen === 'success_screen' && (
              <SuccessScreen
                isAuthenticated={checkAuthenticated.data !== undefined}
                type="textilefunnel"
                classSize={props.form.values.class_size}
                usingNuggit={props.form.values.using_nuggit}
              />
            )}
          </TransitionWrapper>
        </CSSTransition>
      </TransitionGroup>
    </FunnelV2Page>
  );
};

export default (props: HistoryProps) => {
  const form = useForm({
    init: false,
    type: 'textilefunnel',
    roles: '',
    user_origins: ''
  });
  // @ts-ignore
  return <TextileFunnelScreenV2 {...props} form={form} />;
};
