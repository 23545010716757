import React from 'react';
import { Route, Switch } from 'react-router';

import {
  ROUTE_BOOK_FUNNEL,
  ROUTE_BOOK_FUNNEL_V2,
  ROUTE_CLOTHING_FUNNEL,
  ROUTE_TEXTILE_FUNNEL_V2
} from 'utils/constants/routes';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import BookFunnelScreen from './book/BookFunnelScreen';
import ClothingFunnelScreen from './clothing/ClothingFunnelScreen';
import BookFunnelScreenV2 from './v2/BookFunnelScreenV2';
import TextileFunnelScreenV2 from './v2/TextileFunnelScreenV2';

const FunnelRouter = () => (
  <Switch>
    <Route path={ROUTE_CLOTHING_FUNNEL} component={ClothingFunnelScreen} />
    <Route path={ROUTE_BOOK_FUNNEL} component={BookFunnelScreen} />

    <Route path={ROUTE_BOOK_FUNNEL_V2} component={BookFunnelScreenV2} />
    <Route path={ROUTE_TEXTILE_FUNNEL_V2} component={TextileFunnelScreenV2} />

    <NotFoundRoute />
  </Switch>
);

export default FunnelRouter;
