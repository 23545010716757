import React from 'react';
import { FormattedMessage } from 'react-intl';

import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import InputMessage from 'elements/InputMessage';
import Checkboxes from 'screens/funnels/Checkboxes';
import NextButton from 'screens/funnels/NextButton';
import COLORS from 'utils/constants/colors';
import { FormType } from 'utils/hooks/useForm';

const ORIGINS = [
  'Freunde',
  'Vorheriger Jahrgang',
  'Tik Tok',
  'Google',
  'Instagram',
  'Andere'
];

const UserOriginsScreen = ({
  form,
  onSubmit
}: {
  form: FormType;
  onSubmit: () => void;
}) => (
  <PageStackBlock>
    <PageHeader
      headline={<FormattedMessage id="v2 funnel user origins headline" />}
      text={undefined}
    />

    <Checkboxes
      form={form}
      options={ORIGINS}
      name="user_origins"
      translate={false}
    />

    {form.errors.user_origins && (
      <InputMessage
        error={true}
        style={{
          color: `${COLORS.BLACK}`,
          backgroundColor: `${COLORS.RED_LIGHT}`
        }}
      >
        {form.errors.user_origins}
      </InputMessage>
    )}

    <NextButton onClick={onSubmit} condition={!form.loading}>
      <FormattedMessage id="Next" />
    </NextButton>
  </PageStackBlock>
);

export default UserOriginsScreen;
