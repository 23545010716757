import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import FooterWrapper from 'blocks/FooterBlock/FooterWrapper';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import HeaderComponent from 'components/HeaderComponent/HeaderComponent';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import SharingComponent from 'components/SharingComponent';
import SponsorBanner from 'domain/Banner/SponsorBanner';
import StudentsList from 'domain/StudentsList/StudentsList';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ClientStates } from 'models/ClientStateModel';
import { ProfileModelType } from 'models/ProfileModel';
import { pushDashboardViewed } from 'utils/analytics';
import ROLES from 'utils/constants/roles';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { Link } from 'react-router-dom';
import GroupSelectComponent from '../containers/GroupSelectComponent';
import ContentCardComponent from './cards/ContentCardComponent';
import DesignCardComponent from './cards/DesignCardComponent';
import MottoCardComponent from './cards/MottoCardComponent';
import MyClassCardComponent from './cards/MyClassCardComponent';
import PrintCardComponent from './cards/PrintCardComponent';
// import TimePlanCardComponent from './cards/TimePlanCardComponent';
import ProgressBoxComponent from './ProgressBoxComponent';
import RoleChangeComponent from './RoleChangeComponent';
interface MainDashboardScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  profilesStore: ProfilesStoreType;
}

@inject('applicationStore', 'chaptersStore', 'profilesStore')
@observer
class MainDashboardScreen extends React.Component<
  MainDashboardScreenProps & HistoryProps
> {
  componentDidMount() {
    const { applicationStore } = this.props;

    if (!applicationStore.isOrganizer) {
      if (applicationStore.joinFeature('join_book_role_select')) {
        return;
      }
    }

    if (applicationStore.currentUser) {
      pushDashboardViewed(applicationStore.currentUser.role || ROLES.STUDENT);
    }

    if (applicationStore.atLeastStudent) {
      if (
        applicationStore.onboardFeature(
          applicationStore.isOrganizer
            ? 'dashboard_organizer'
            : 'dashboard_student'
        )
      ) {
        return;
      }

      this.loadDashboard();
    }
  }

  private loadDashboard() {
    this.props.chaptersStore.getMainDashboard();
    this.props.profilesStore.getStudents();
  }

  private generateHeadline(text?: ReactNode) {
    const { currentUser } = this.props.applicationStore;

    return (
      <>
        {currentUser && (
          <>
            {currentUser.group ? (
              <ItemStackBlock gap="XS">
                <ItemStackBlock gap="XXS">
                  <Headline.Large>
                    <FormattedMessage
                      id="Hello {name}!"
                      values={{ name: currentUser.first_name }}
                    />
                  </Headline.Large>
                  <Paragraph>
                    <IconComponent icon="USER" />
                    {currentUser.group.name}
                  </Paragraph>
                </ItemStackBlock>
                <Paragraph size="L" color="TEXT_LIGHT">
                  {text}
                </Paragraph>
              </ItemStackBlock>
            ) : (
              <PageHeader
                headline={
                  <>
                    {' '}
                    {currentUser ? (
                      <FormattedMessage
                        id="Hello {name}!"
                        values={{ name: currentUser.first_name }}
                      />
                    ) : (
                      <FormattedMessage id="Hello!" />
                    )}
                  </>
                }
                text={text}
              />
            )}
          </>
        )}
      </>
    );
  }

  private renderPage(content?: any) {
    return (
      <FooterWrapper noMarginBottom={true}>
        <HeaderComponent />

        <PageStackBlock>{content}</PageStackBlock>

        <HelpSubject subject="dashboard" />
      </FooterWrapper>
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  private renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadDashboard()} />
    );
  }

  private renderApplicant() {
    return this.renderPage(
      <>
        {this.generateHeadline(
          <FormattedMessage id="dashboard applicant info student" />
        )}

        <Divider />

        <Headline.Medium>
          <FormattedMessage id="Your organizers" />
        </Headline.Medium>

        <StudentsList
          organizers={true}
          itemComponent={({ profile }: { profile: ProfileModelType }) => (
            <Paragraph>{profile.fullName}</Paragraph>
          )}
        />
      </>
    );
  }

  private renderDashboard() {
    const { applicationStore, chaptersStore, profilesStore } = this.props;
    const { mainDashboard: dashboard } = chaptersStore;
    const { currentUser, isOrganizer, book } = applicationStore;

    if (!dashboard || !currentUser || !book) {
      return this.renderPage();
    }

    const students = profilesStore.studentsWithoutGroup;

    const studentCardsPresent =
      dashboard.has_any_chapters || !!book.motto || !!book.allow_motto_vote
        ? true
        : false;

    return this.renderPage(
      <>
        {this.generateHeadline(<FormattedMessage id="dashboard label" />)}

        {isOrganizer && students.length > 0 && book.groups_applied && (
          <Link to={ROUTE_SETTINGS + '/students_without_group?back=dashboard'}>
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage
                      id="students without group"
                      values={{
                        count: students.length
                      }}
                    />
                  </EveryCardHeadline>
                </EveryCardBody>
                <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
              </EveryCardPadding>
            </EveryCard>
          </Link>
        )}

        <GroupSelectComponent from="dashboard" />

        {!book.isLockedForOrder && (
          <RoleChangeComponent dashboard={dashboard} />
        )}
        <ProgressBoxComponent />

        {!book.isLockedForOrder && (
          <SharingComponent
            label="invite your fellow students"
            buttonOutside={false}
            modalHeader="invite link"
            message="Nuggit invite text"
            buttonColor="WHATSAPP"
            url={book.invitation_url}
          />
        )}

        <Divider />

        {dashboard.banner && dashboard.banner.image && (
          <SponsorBanner
            link={dashboard.banner.link ? dashboard.banner.link : ''}
            imagePath={dashboard.banner.image ? dashboard.banner.image : ''}
          />
        )}

        {studentCardsPresent || isOrganizer ? (
          <>
            <Headline.Medium>
              <FormattedMessage id="Your book" />
            </Headline.Medium>

            <ItemStackBlock gap="S">
              <TwoColumnBlock gap="M">
                {/* {isOrganizer && (
                <TimePlanCardComponent
                  book={book}
                  onboardingState={applicationStore.clientState(
                    ClientStates.ob_timeplan
                  )}
                />
              )} */}

                <MottoCardComponent
                  book={book}
                  votesCount={dashboard.motto_votes_count}
                  hasMottoVote={dashboard.has_motto_vote}
                  isOrganizer={isOrganizer}
                  onboardingState={applicationStore.clientState(
                    ClientStates.ob_motto
                  )}
                />

                <ContentCardComponent
                  book={book}
                  hasAnyChapters={dashboard.has_any_chapters}
                  hasOwnContent={dashboard.has_own_content}
                  isOrganizer={isOrganizer}
                  onboardingState={applicationStore.clientState(
                    ClientStates.ob_content
                  )}
                />

                {isOrganizer && (
                  <>
                    <DesignCardComponent
                      book={book}
                      cover={dashboard.cover}
                      onboardingState={applicationStore.clientState(
                        ClientStates.ob_design
                      )}
                    />

                    <MyClassCardComponent
                      book={book}
                      profilesCount={dashboard.profiles_count}
                      organizersCount={dashboard.organizers?.size}
                      onboardingState={applicationStore.clientState(
                        ClientStates.ob_class
                      )}
                    />

                    <PrintCardComponent
                      book={book}
                      checkoutPresent={dashboard.checkout_present}
                      checkoutIsAuthor={dashboard.checkout_is_author}
                      parentalApprovalUploaded={
                        dashboard.parental_approval_uploaded
                      }
                      parentalApprovalChecked={
                        dashboard.parental_approval_checked
                      }
                      paymentReceived={dashboard.payment_received}
                      onboardingState={applicationStore.clientState(
                        ClientStates.ob_print
                      )}
                    />
                  </>
                )}
              </TwoColumnBlock>
            </ItemStackBlock>
          </>
        ) : (
          ''
        )}
      </>
    );
  }

  render() {
    if (!this.props.applicationStore.atLeastStudent) {
      return this.renderApplicant();
    }

    const {
      mainDashboardLoadingState: loadingState
    } = this.props.chaptersStore;

    if (loadingState === 'loading') {
      return this.renderLoading();
    }

    if (loadingState === 'error') {
      return this.renderError();
    }

    return this.renderDashboard();
  }
}

export default MainDashboardScreen;
